<template>
  <allocationForm />
</template>
<script>
import allocationForm from './components/allocationsForm.vue'
export default {
  name:'create',
  components:{allocationForm}
}
</script>
